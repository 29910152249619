.About {
    display: flex;
    flex-direction: row;
}

.About--Bio {
    line-height: 1.5;
    padding: 2rem 2rem 2rem 0;
}

.About--ImageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    object-fit: cover;
}

.About--Image {
    max-width: 100%;
    height: auto;
    display: block;
}