@import 'src/constants.scss';

.Contact {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Contact--FormRow {
    display: flex;
    justify-content: center;
    padding: 1rem 8rem;
    width: 75vw;
}


@media screen and (min-width: $MOBILE_BREAKPOINT) {
    .Contact--FormRow {
        width: 50vw;
    }
}

.Contact--Input {
    background-color: #fafafa;
    border: 1px solid #a9a9a9;
    padding: 1rem;
    width: 100%;
}

.Contact--Input:focus {
    outline: none;
    border-color: $background-color;
}

.Contact--Button {
    padding: 1rem 2rem;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.Contact--Button:hover {
    background-color: $button-hover-color;
}

.Contact--Modal {
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    margin: 0 8rem;
    position: fixed;
    padding: 2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Contact--Modal:focus-visible {
    outline: $background-color;
}

.Contact--ModalButton {
    margin-top: 1rem;
}