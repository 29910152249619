@import 'src/constants.scss';

.Footer {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    background-color: $background-color;
    padding: 2rem;
}

.Footer--Icon {
    margin: 0 0.5rem;
    height: 28px;
    width: 28px;
}