@import 'src/constants.scss';

.Writing--Feature {
    display: flex;
    flex-direction: row;
}

.Writing--FeatureImage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 1rem;
    max-width: 100%;
}

.Writing--FeatureText {
    padding: 1rem 1rem 1rem 0;
}

.Writing--Button {
    padding: 1rem 2rem;
    background-color: black;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.Writing--Button:hover {
    background-color: $button-hover-color;
}

.Writing--Video {
    display: flex;
    justify-content: center;
    margin: 2rem;
}