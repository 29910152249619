@import 'src/constants.scss';

@font-face {
  font-family: 'Comic Neue';
  src: url('../fonts/ComicNeue-Angular/ComicNeueAngular-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Comic Neue Bold';
  src: url('../fonts/ComicNeue-Angular/ComicNeueAngular-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'HachiMaru';
  src: url('../fonts/Hachi_Maru_Pop/HachiMaruPop-Regular.ttf') format('truetype');
}

.App {
  font-family: 'Comic Neue', sans-serif;
  background-color: $background-color;
}

@media screen and (min-width: $TABLET_BREAKPOINT) {
  .App {
    padding: 0 8rem;
  }
}

@media screen and (max-width: $TABLET_BREAKPOINT) {
  .App {
    padding: 0 2rem;
  }
}